import React from "react";
import "./TermsAndCondition.scss";
const Policy = () => {
  return (
    <div className="terms-container">
      <h4>Privacy Policy</h4>
      <div className="contents">
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}></p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          PC24/7 Inc. (“<strong>PC24/7</strong>” the “<strong>Company</strong>”
          “<strong>we</strong>” and “<strong>us</strong>”) provides a
          marketplace to bring together collectors and enthusiasts and make it
          easy and safe to connect, buy and sell. This Privacy Policy is
          designed to help you understand how we collect, use, and share your
          personal information and to help you understand and exercise your
          privacy rights.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>SCOPE</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          This Privacy Policy applies to personal information processed by us,
          including on our websites, mobile applications, and other online or
          offline offerings. To make this Privacy Policy easier to read, our
          websites, mobile applications, and other services, offerings, sales,
          marketing or events are collectively called "&nbsp;
          <strong>Services.</strong>" PC24/7 is the controller ("Controller")
          for the personal information we process, unless otherwise stated. For
          information on how to contact us, please refer to the “contact us”
          section below.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>PERSONAL INFORMATION WE COLLECT</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          The categories of personal information we collect depend on how you
          interact with us, our Services, and the requirements of applicable
          law. We collect information that you provide to us, information we
          obtain automatically when you use our Services, and information from
          other sources such as third-party services and organizations, as
          described below.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Personal Information You Provide to Us Directly</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We may collect the following personal information that you provide to
          us:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Name and Contact Information</strong>. We may collect
          information when you create an account or use our Services, such as
          first and last name, email address, postal address, phone number, and
          other contact data.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Credentials.</strong>&nbsp;We may collect passwords, password
          hints, and similar security information used for authentication and
          account access.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Purchases</strong>. We may collect personal information and
          details associated with your purchases, including payment information.
          Any payments made via our Services are processed by Stripe. Stripe may
          also collect a picture of your government-issued ID for identification
          verification purposes when you make purchases via the Services. You
          may find their privacy policy at&nbsp;
          <a
            href="https://stripe.com/privacy"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>
                https://stripe.com/privacy
              </span>
            </u>
          </a>
          . We do not directly collect or store any payment card numbers entered
          through our Services, but we may receive information associated with
          your payment card information (e.g., your billing details).
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Your Communications with Us</strong>. We may collect personal
          information, such as email address, phone number, or mailing address
          when you request information about our Services, register for our
          newsletter or loyalty program, request customer or technical support,
          apply for a job, or otherwise communicate with us.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Surveys</strong>. We may contact you to participate in
          surveys. If you decide to participate, you may be asked to provide
          certain information which may include personal information.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Interactive Features</strong>. We and others who use our
          Services may collect personal information that you submit or make
          available through our interactive features (e.g., messaging and chat
          features, commenting functionalities, forums, blogs, and social media
          pages). Any information you provide using the public sharing features
          of the Services (referred to herein as "&nbsp;
          <strong>User Content</strong>") will be considered "public," unless
          otherwise required by applicable law, and is not subject to this
          Privacy Policy. Please exercise caution before revealing any
          information that may identify you in the real world to other users.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Recorded Streaming (Video on Demand)</strong>. When you stream
          on our Services, we may record and store your streaming session,
          including your image and voice, for a limited period of time. We may
          use such recordings for transaction verification and fraud prevention
          purposes.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Livestream Clipping</strong>. When you stream on our Services,
          other users will be able to take a clip of your livestream, including
          your image and voice, and publish and share it on their PC24/7 profile
          or to their personal social media channels outside the Services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Local Pickup Information</strong>. We may collect your postal
          address to determine your distance from other users to facilitate
          local pickups from buyers near you. If you are a seller and choose to
          allow for a local pickup, your zip code will be made public and your
          postal address will be shared with buyers who opt to do a local pickup
          from you. If you are a seller, you can choose to publish your postal
          address on your profile.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Sweepstakes, Contests, and Promotions</strong>. We may collect
          personal information you provide for any sweepstakes, contests, or
          other promotions that we offer. In some jurisdictions, we are required
          to publicly share information of sweepstakes and contest winners.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Conferences, Trade Shows, and Other Events.</strong>&nbsp;We
          may collect personal information from individuals when we attend
          conferences, trade shows, and other events.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Business Development and Strategic Partnerships.</strong>
          &nbsp;We may collect personal information from individuals and third
          parties to assess and pursue potential business opportunities.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Job Applications.</strong>&nbsp;We may post job openings and
          opportunities on our Services. If you reply to one of these postings
          by submitting your application, CV and/or cover letter to us, we will
          collect and use your information to assess your qualifications.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Personal Information Collected Automatically</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We may collect the following personal information automatically when
          you use our Services:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Device and Usage Information</strong>. We may collect certain
          information automatically when you use our Services, such as your
          Internet protocol (IP) address, user settings, MAC address, cookie
          identifiers, mobile carrier, mobile advertising and other unique
          identifiers, browser or device information, location information
          (including approximate location derived from IP address), and Internet
          service provider. We may also automatically collect information
          regarding your use of our Services, such as pages that you visit
          before, during and after using our Services, information about the
          links you click, the types of content you interact with, the frequency
          and duration of your activities, and other information about how you
          use our Services. In addition, we may collect information that other
          people provide about you when they use our Services, including
          information about you when they tag you.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>
            Cookies, Pixel Tags/Web Beacons, and Other Technologies
          </strong>
          . We, as well as third parties that provide content, advertising, or
          other functionality on our Services, may use cookies, pixel tags,
          local storage, and other technologies ("&nbsp;
          <strong>Technologies</strong>") to automatically collect information
          through your use of our Services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Cookies</strong>. Cookies are small text files placed in
          device browsers that store preferences and facilitate and enhance your
          experience.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Pixel Tags/Web Beacons</strong>. A pixel tag (also known as a
          web beacon) is a piece of code embedded in our Services that collects
          information about engagement on our Services. The use of a pixel tag
          allows us to record, for example, that a user has visited a particular
          web page or clicked on a particular advertisement. We may also include
          web beacons in e-mails to understand whether messages have been
          opened, acted on, or forwarded.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Our uses of these Technologies fall into the following general
          categories:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Operationally Necessary</strong>. This includes Technologies
          that allow you access to our Services, applications, and tools that
          are required to identify irregular website behavior, prevent
          fraudulent activity and improve security or that allow you to make use
          of our functionality;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Performance-Related</strong>. We may use Technologies to
          assess the performance of our Services, including as part of our
          analytic practices to help us understand how individuals use our
          Services (<em>see Analytics below</em>);
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Functionality-Related</strong>. We may use Technologies that
          allow us to offer you enhanced functionality when accessing or using
          our Services. This may include identifying you when you sign into our
          Services or keeping track of your specified preferences, interests, or
          past items viewed;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Advertising- or Targeting-Related</strong>. We may use first
          party or third-party Technologies to deliver content, including ads
          relevant to your interests, on our Services or on third-party
          websites.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Analytics</strong>. We may use Technologies and other
          third-party tools to process analytics information on our Services.
          Some of our analytics partners include:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Google Analytics</strong>. For more information, please
          visit&nbsp;
          <a
            href="http://www.google.com/policies/privacy/partners/"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>
                Google Analytics' Privacy Policy
              </span>
            </u>
          </a>
          . To learn more about how to opt-out of Google Analytics' use of your
          information, please click&nbsp;
          <a
            href="http://tools.google.com/dlpage/gaoptout"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>here</span>
            </u>
          </a>
          .
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Segment</strong>. For more information about Segment, please
          visit&nbsp;
          <a
            href="https://segment.com/legal/privacy/"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>Segment's Privacy Policy</span>
            </u>
          </a>
          .
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>AppsFlyer</strong>. For more information about AppsFlyer,
          please visit&nbsp;
          <a
            href="https://www.appsflyer.com/legal/services-privacy-policy/"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>
                AppsFlyer's Privacy Policy
              </span>
            </u>
          </a>
          .
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Facebook Connect</strong>. For more information about
          Facebook's use of your personal information, please visit&nbsp;
          <a
            href="https://www.facebook.com/policy.php"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>Facebook'sData Policy</span>
            </u>
          </a>
          . To learn more about how to opt-out of Facebook's use of your
          information, please click&nbsp;
          <a
            href="https://www.facebook.com/settings?tab=ads"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>here</span>
            </u>
          </a>
          &nbsp;while logged in to your Facebook account.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Social Media Platforms.</strong>&nbsp;Our Services may contain
          social media buttons such as Instagram, TikTok, Twitter and Facebook
          (that might include widgets such as the "share this" button or other
          interactive mini programs). These features may collect information
          such as your IP address, which page you are visiting on our Services,
          and may set a cookie to enable the feature to function properly. Your
          interactions with these platforms are governed by the privacy policy
          of the company providing it.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Personal Information Collected from Other Sources</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We may obtain personal information about you from other sources,
          including through third-party services and organizations. For example,
          if you access our Services through a third-party application, such as
          an app store, a third-party login service, or a social networking
          site, we may collect personal information about you from that
          third-party application that you have made available via your privacy
          settings.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>HOW WE USE YOUR PERSONAL INFORMATION</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We use your personal information for a variety of business purposes,
          including to provide our Services, for administrative purposes, and to
          market our products and Services, as described below.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Provide Our Services</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We use your personal information to provide you with our Services,
          such as:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Managing your information and accounts;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Providing access to certain areas, functionalities, and features of
          our Services;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Fulfilling and managing your orders, payments, returns, and exchanges
          made through the Services;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Administering prize draws and competitions when you elect to
          participate in competitions;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Answering requests for customer or technical support;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Communicating with you about your account, activities on our Services,
          and policy changes;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Enabling our payment processor to process your financial information
          and other payment methods for products or Services purchased;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Processing applications if you apply for a job with us; and
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Allowing you to register for events.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Administrative Purposes</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We use your personal information for various administrative purposes,
          such as:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Pursuing our legitimate interests such as research and development
          (including marketing research), network and information security, and
          fraud prevention;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Detecting security incidents, protecting against malicious, deceptive,
          fraudulent or illegal activity, and prosecuting those responsible for
          that activity;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Measuring interest and engagement in our Services;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Short-term, transient use, such as contextual customization of ads;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Improving, upgrading or enhancing our Services;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Developing new products and Services;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Ensuring internal quality control and safety;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Authenticating and verifying individual identities, including requests
          to exercise your rights under this policy;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Debugging to identify and repair errors with our Services;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Auditing relating to interactions, transactions and other compliance
          activities;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Sharing personal information with third parties as needed to provide
          the Services;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Enforcing our agreements and policies; and
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Complying with our legal obligations.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Marketing and Advertising our Products and Services</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We may use personal information to tailor and provide you with content
          and advertisements. We may provide you with these materials as
          permitted by applicable law.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          Some of the ways we market to you include email campaigns, custom
          audiences advertising, and "interest-based" or "personalized
          advertising," including through cross-device tracking.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          If you have any questions about our marketing practices or if you
          would like to opt out of the use of your personal information for
          marketing purposes, you may contact us at any time as set forth in
          “contact us” below.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Other Purposes</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We also use your personal information for other purposes as requested
          by you or as permitted by applicable law.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Consent</strong>. We may use personal information for other
          purposes that are clearly disclosed to you at the time you provide
          personal information or with your consent.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Automated Decision Making</strong>. We may engage in automated
          decision making, including profiling for anti-fraud purposes. PC24/7's
          processing of your personal information will not result in a decision
          based solely on automated processing that significantly affects you
          unless such a decision is necessary as part of a contract we have with
          you, we have your consent, or we are permitted by law to engage in
          such automated decision making. If you have questions about our
          automated decision making, you may contact us as set forth in “contact
          us” below.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Customizations.</strong>&nbsp;We may use your personal
          information to improve your user experience on the Services by
          providing content recommendations in your feed such as sellers, items
          or products that you may find interesting based on the actions you
          take on the Services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>De-identified and Aggregated Information</strong>. We may use
          personal information and other information about you to create
          de-identified and/or aggregated information, such as de-identified
          demographic information, de-identified purchase information,
          information about the device from which you access our Services, or
          other analyses we create. This information does not identify you or
          other users directly and is not personal information subject to this
          Privacy Policy.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Share Content with Friends.</strong>&nbsp;Our Services may
          offer various tools and functionalities. For example, we may allow you
          to provide personal information about your friends, or your friends to
          provide personal information about you, through our referral services.
          Our referral services may allow you to forward or share certain
          content with a friend, such as an email inviting your friend to use
          our Services. Please only share with us contact information of people
          with whom you have a relationship (e.g., relative, friend, neighbor,
          or co-worker).
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Find Friends and Contacts on PC24/7</strong>. When you create
          an account on the PC24/7 mobile application on your mobile device,
          PC24/7 will ask your permission to access your phone contacts to
          determine if you are already connected to existing PC24/7 users, so
          that you can follow such contacts and invite contacts who are not
          users to join the Services. We may also receive your contact
          information when another user shares your contact information with us,
          so that that user may invite you to join the Services, and you and
          that user can know to follow each other on PC24/7. You are solely
          responsible for obtaining consent from your contacts. You can always
          change whether to allow PC24/7 to access your contacts through the
          privacy settings on your mobile device.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>HOW WE DISCLOSE YOUR PERSONAL INFORMATION</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We disclose your personal information to third parties for a variety
          of business purposes, including to provide our Services, to protect us
          or others, or in the event of a major business transaction such as a
          merger, sale, or asset transfer, as described below.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Disclosures to Provide our Services</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          The categories of third parties with whom we may share your personal
          information are described below.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Service Providers</strong>. We may share your personal
          information with our third-party service providers who use that
          information to help us provide our Services. This includes service
          providers that provide us with IT support, hosting, payment
          processing, customer service, and related services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Business Partners</strong>. We may share your personal
          information with business partners to provide you with a product or
          service you have requested. We may also share your personal
          information to business partners with whom we jointly offer products
          or services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Affiliates</strong>. We may share your personal information
          with our company affiliates for our administrative purposes, including
          activities such as IT management, for them to provide services to you
          or support and supplement the Services we provide. Affiliates include
          our parent company and any subsidiaries, joint venture partners or
          other companies that we control or that are under common control with
          us.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Other Users/Website Visitors.</strong>&nbsp;As described above
          in "personal information we collect” our Services allow you to share
          your profile and/or User Content with other users/publicly, including
          to those who do not use our Services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Advertising Partners</strong>. We may share your personal
          information with third-party analytics and advertising partners. These
          third-party analytics and advertising partners may set Technologies
          and other tracking tools on our Services to collect information
          regarding your activities and your device (e.g., your IP address,
          cookie identifiers, page(s) visited, location, time of day). These
          advertising partners may use this information (and similar information
          collected from other services) for purposes of delivering personalized
          advertisements to you when you visit digital properties within their
          networks. This practice is commonly referred to as "interest-based
          advertising" or "personalized advertising."
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>APIs/SDKs</strong>. We may use third-party Application Program
          Interfaces ("APIs") and Software Development Kits ("SDKs") as part of
          the functionality of our Services. For more information about our use
          of APIs and SDKs, please contact us as set forth in “contact us”
          below.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Disclosures to Protect Us or Others</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We may access, preserve, and disclose any information we store
          associated with you to external parties if we, in good faith, believe
          doing so is required or appropriate to: comply with law enforcement or
          national security requests and legal process, such as a court order or
          subpoena; protect your, our, or others' rights, property, or safety;
          enforce our policies or contracts; collect amounts owed to us; or
          assist with an investigation or prosecution of suspected or actual
          illegal activity.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>
            Disclosure in the Event of Merger, Sale, or Other Asset Transfers
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          If we are involved in a merger, acquisition, financing due diligence,
          reorganization, bankruptcy, receivership, purchase or sale of assets,
          or transition of service to another provider, your personal
          information may be sold or transferred as part of such a transaction,
          as permitted by law and/or contract.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>YOUR PRIVACY CHOICES AND RIGHTS</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Your Privacy Choices</strong>. The privacy choices you may
          have about your personal information are determined by applicable law
          and are described below.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Email and Telephone Communications</strong>. If you receive an
          unwanted email from us, you can use the unsubscribe link found at the
          bottom of the email to opt out of receiving future emails. Note that
          you will continue to receive transaction-related emails regarding
          products or Services you have requested. We may also send you certain
          non-promotional communications regarding us and our Services, and you
          will not be able to opt out of those communications (e.g.,
          communications regarding our Services or updates to our Terms or this
          Privacy Policy). We process requests to be placed on do-not-mail,
          do-not-phone, and do-not-contact lists as required by applicable law.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Text Messages.</strong>&nbsp;You may opt out of receiving text
          messages from us by updating your preferences in your account or by
          otherwise contacting us as described in “contact us” below.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Mobile Devices</strong>. We may send you push notifications
          through our mobile application. You may opt out from receiving these
          push notifications by changing the settings on your mobile device.
          With your consent, we may also collect precise location-based
          information via our mobile application. You may opt out of this
          collection by changing the settings on your mobile device.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          "&nbsp;<strong>Do Not Track</strong>. " Do Not Track ("DNT") is a
          privacy preference that users can set in certain web browsers. Please
          note that we do not respond to or honor DNT signals or similar
          mechanisms transmitted by web browsers.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Cookies and Interest-Based Advertising</strong>. You may stop
          or restrict the placement of Technologies on your device or remove
          them by adjusting your preferences as your browser or device permits.
          However, if you adjust your preferences, our Services may not work
          properly. Please note that cookie-based opt-outs are not effective on
          mobile applications. However, you may opt-out of personalized
          advertisements on some mobile applications by following the
          instructions for&nbsp;
          <a
            href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=en"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>Android</span>
            </u>
          </a>
          ,&nbsp;
          <a
            href="https://support.apple.com/en-us/HT202074"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>iOS</span>
            </u>
          </a>
          &nbsp;and&nbsp;
          <a
            href="https://www.networkadvertising.org/mobile-choice/"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>others</span>
            </u>
          </a>
          . The online advertising industry also provides websites from which
          you may opt out of receiving targeted ads from data partners and other
          advertising partners that participate in self-regulatory programs. You
          can access these and learn more about targeted advertising and
          consumer choice and privacy by visiting the&nbsp;
          <a
            href="http://www.networkadvertising.org/managing/opt_out.asp"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>
                Network Advertising Initiative
              </span>
            </u>
          </a>
          ,&nbsp;
          <a
            href="http://www.aboutads.info/choices/"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>
                the Digital Advertising Alliance
              </span>
            </u>
          </a>
          ,&nbsp;
          <a
            href="https://www.youronlinechoices.eu/"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>
                the European Digital Advertising Alliance
              </span>
            </u>
          </a>
          , and&nbsp;
          <a
            href="https://youradchoices.ca/choices/"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>
                the Digital Advertising Alliance of Canada
              </span>
            </u>
          </a>
          . Please note you must separately opt out in each browser and on each
          device.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Your Privacy Rights</strong>. In accordance with applicable
          law, you may have the right to:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Access Personal Information</strong>&nbsp;about you,
          including: (i) confirming whether we are processing your personal
          information; (ii) obtaining access to or a copy of your personal
          information;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Request Correction</strong>&nbsp;of your personal information
          where it is inaccurate, incomplete or outdated. In some cases, we may
          provide self-service tools that enable you to update your personal
          information;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Request Deletion, Anonymization or Blocking</strong>&nbsp;of
          your personal information when processing is based on your consent or
          when processing is unnecessary, excessive or noncompliant;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Request Restriction of or Object to</strong>&nbsp;our
          processing of your personal information when processing is
          noncompliant;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Withdraw your Consent</strong>&nbsp;to our processing of your
          personal information. If you refrain from providing personal
          information or withdraw your consent to processing, some features of
          our Service may not be available;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Request data portability</strong>&nbsp;and&nbsp;
          <strong>
            receive an electronic copy of personal information that you have
            provided to us;
          </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Be informed</strong>&nbsp;about third parties with which your
          personal information has been shared; and
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Request the review</strong>&nbsp;
          <strong>
            of decisions taken exclusively based on automated processing
          </strong>
          &nbsp;if that could affect data subject rights.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Request to Opt-Out of Certain Processing Activities</strong>
          &nbsp;including, as applicable, if we process your personal
          information for “targeted advertising” (as “targeted advertising” is
          defined by applicable privacy laws), if we “sell” your personal
          information (as “sell” is defined by applicable privacy laws), or if
          we engage in “profiling” in furtherance of certain “decisions that
          produce legal or similarly significant effects” concerning you (as
          such terms are defined by applicable privacy laws);
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Appeal our Decision</strong>&nbsp;to decline to process your
          request.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          If you would like to exercise any of these rights, please contact us
          as set forth in “contact us” below. We will process such requests in
          accordance with and to the extent required by applicable laws.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>SECURITY OF YOUR PERSONAL INFORMATION</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We use a combination of physical, technical, and administrative
          safeguards to protect the information we collect through the Services.
          While we use these precautions to safeguard your information, no
          system is 100% secure, and we cannot guarantee the security of the
          networks, systems, servers, devices, and databases we operate or that
          are operated on our behalf. To the fullest extent permitted by
          applicable law, we do not accept liability for unauthorized
          disclosure.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          By using our Services or providing personal information to us, you
          agree that we may communicate with you electronically regarding
          security, privacy, and administrative issues relating to your use of
          our Services. If we learn of a security system's breach, we may
          attempt to notify you electronically by posting a notice on our
          Services, by mail or by sending an email to you.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>INTERNATIONAL DATA TRANSFERS</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          All information processed by us may be transferred, processed, and
          stored anywhere in the world, including, but not limited to, the
          United States or other countries, which may have data protection laws
          that are different from the laws where you live. We endeavor to
          safeguard your information consistent with the requirements of
          applicable laws.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          If we transfer personal information to countries outside the European
          Economic Area, we will put in place appropriate safeguards to ensure
          that this transfer complies with the applicable laws and regulations.
          For more information about these safeguards, please contact us as set
          forth below.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>RETENTION OF PERSONAL INFORMATION</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We store the personal information we collect as described in this
          Privacy Policy for as long as you use our Services or as necessary to
          fulfill the purpose(s) for which it was collected, provide our
          Services, resolve disputes, establish legal defenses, conduct audits,
          pursue legitimate business purposes, enforce our agreements, and
          comply with applicable laws.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          This Supplemental California Privacy Notice only applies to our
          processing of personal information that is subject to the California
          Consumer Privacy Act of 2018, as amended by the California Privacy
          Rights Act of 2020 (as amended, "CCPA"). The CCPA provides California
          residents with the right to know what categories of personal
          information PC24/7 has collected about them and whether PC24/7
          disclosed that personal information for a business purpose (e.g., to a
          service provider), whether PC24/7 “sold” that personal information,
          and whether PC24/7 “shared” that personal information for
          “cross-context behavioral advertising” in the preceding twelve months.
          California residents can find this information below:
        </p>
        <table
          cellSpacing={2}
          cellPadding={0}
          style={{ borderSpacing: "1.5pt" }}
        >
          <tbody>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>
                    Category of Personal Information Collected by PC24/7
                  </strong>
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>
                    Category of Third Parties Personal Information is Disclosed
                    to for a Business Purpose
                  </strong>
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>
                    Is the Personal Information Shared with Any Third Parties
                    for Cross-Context Behavioral Advertising?
                  </strong>
                </p>
              </td>
              <td style={{ verticalAlign: "top" }}></td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>Identifiers.</strong>
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  A real name, alias, postal address, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, account name, or other similar identifiers.
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Advertising networks
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Data analytics providers
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Government entities
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Social networks
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Service providers
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Yes, advertising networks
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>
                    Personal information categories listed in the California
                    Customer Records statute (Cal. Civ. Code § 1798.80(e))
                  </strong>
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <em>For instance,</em>
                  <em>&nbsp;</em>A name, signature, address, telephone number,
                  driver’s license or state identification card number, bank
                  account number, credit card number, debit card number, or any
                  other financial information. Personal Information does not
                  include publicly available information that is lawfully made
                  available to the general public from federal, state, or local
                  government records. Note: Some personal information included
                  in this category may overlap with other categories.
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Advertising networks
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Data analytics providers
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Government entities
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Social networks
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Service providers
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Yes, advertising networks
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>
                    Protected classification characteristics under California or
                    federal law
                  </strong>
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Age (40 years or older), race, color, ancestry, national
                  origin, citizenship, religion or creed, marital status,
                  medical condition, physical or mental disability, sex
                  (including gender, gender identity, gender expression,
                  pregnancy or childbirth and related medical conditions),
                  sexual orientation, veteran or military status, genetic
                  information (including familial genetic information).
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Only for job candidates and employees:
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Government entities
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Service providers
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  No
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>Commercial information</strong>
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Records of personal property, products or services purchased,
                  obtained, or considered, or other purchasing or consuming
                  histories or tendencies.
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Advertising networks
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Data analytics providers
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Government entities
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Social networks
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Service providers
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Other users/ Public
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Yes, advertising networks
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>Internet or other electronic network activity</strong>
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Browsing history, search history, information on a consumer’s
                  interaction with an internet website, application, or
                  advertisement.
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Advertising networks
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Data analytics providers
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Government entities
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Social networks
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Service providers
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Yes, advertising networks
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>Geolocation data</strong>
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Coarse physical location or movements (e.g., zip code, IP
                  address).
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Data analytics providers
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Service providers
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Other users/Public
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  No
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>Sensory data</strong>
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Audio, electronic, visual, or similar information.
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Data analytics providers
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Government entities
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Social networks
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Service providers
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  No
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>
                    Inferences drawn from other personal information to create a
                    profile about a consumer
                  </strong>
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Profile reflecting a consumer’s preferences, characteristics,
                  predispositions, behavior, attitudes, intelligence, abilities,
                  and aptitudes.
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Advertising networks
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Data analytics providers
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Government entities
                </p>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  - Service providers
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Yes, advertising networks
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>
                    Personal information that reveals a consumer’s social
                    security, driver’s license, state identification card, or
                    passport number
                  </strong>
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Service providers (solely to verify the identity of buyers and
                  sellers in very limited circumstances)
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  No
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>
                    Personal information that reveals a consumer’s account
                    log-in, financial account, debit card, or credit card number
                    in combination with any required security or access code,
                    password, or credentials allowing access to an account
                  </strong>
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Not Applicable
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  No
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>
                    Personal information that reveals a consumer’s precise
                    geolocation
                  </strong>
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Not Applicable
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  No
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>
                    Personal information that reveals a consumer’s racial or
                    ethnic origin, religious or philosophical beliefs, or union
                    membership
                  </strong>
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Not Applicable
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  No
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>
                    Personal information that reveals the contents of a
                    consumer’s mail, email, and text messages unless PC24/7 is
                    the intended recipient of the communication
                  </strong>
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Not Applicable
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  No
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>
                    Personal information that reveals consumer’s genetic data
                  </strong>
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Not Applicable
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  No
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>
                    Biometric information that is processed for the purpose of
                    uniquely identifying a consumer
                  </strong>
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Not Applicable
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  No
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>
                    Personal information collected and analyzed concerning a
                    consumer’s health
                  </strong>
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Not Applicable
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  No
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  <strong>
                    Personal information collected and analyzed concerning a
                    consumer’s sex life or sexual orientation
                  </strong>
                </p>
              </td>
              <td style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  Not Applicable
                </p>
              </td>
              <td colSpan={2} style={{ verticalAlign: "middle" }}>
                <p
                  style={{
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    fontSize: "12pt",
                  }}
                >
                  No
                </p>
              </td>
            </tr>
            <tr style={{ height: "0pt" }}>
              <td style={{ width: "239.25pt" }}></td>
              <td style={{ width: "116.8pt" }}></td>
              <td style={{ width: "109.7pt" }}></td>
              <td style={{ width: "2.25pt" }}></td>
            </tr>
          </tbody>
        </table>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          The categories of sources from which we collect personal information
          and our business and commercial purposes for using personal
          information are set forth in "Personal Information We Collect" and
          "How We Use Your Personal Information" and “How We Disclose Your
          Personal Information” above, respectively. We will retain personal
          information in accordance with the time periods set forth in
          “Retention of Personal Information.”
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          We “share” your personal information when we provide you with
          “cross-context behavioral advertising” about PC24/7’s products and
          services. We do not “sell” your personal information.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Additional Privacy Rights for California Residents</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>
            Opting Out of “Sales” of Personal Information and/or “Sharing” for
            Cross-Context Behavioral Advertising under the CCPA.
          </strong>
          &nbsp;California residents have the right to opt out of the “sale” of
          personal information and the “sharing” of personal information for
          “cross-context behavioral advertising.” We do not “Sell” Personal
          Information. California residents may exercise the right to opt out of
          “sharing” by contacting us as set forth in the “Contact Us” section
          below.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Disclosure Regarding Individuals Under the Age of 16.</strong>
          &nbsp;PC24/7 does not have actual knowledge of any “sale” of personal
          information of minors under 16 years of age. PC24/7 does not have
          actual knowledge of any “sharing” of personal information of minors
          under 16 years of age for “cross-context behavioral advertising.”
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Disclosure Regarding Sensitive Personal Information</strong>.
          PC24/7 only uses and discloses sensitive personal information&nbsp;for
          the following purposes:
        </p>
        <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginLeft: "27.6pt",
              paddingLeft: "8.4pt",
              fontFamily: "serif",
              fontSize: "10pt",
            }}
          >
            <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
              To prevent, detect, and investigate security incidents that
              compromise the availability, authenticity, integrity, and or
              confidentiality of stored or transmitted personal information.
            </span>
          </li>
          <li
            style={{
              marginLeft: "27.6pt",
              paddingLeft: "8.4pt",
              fontFamily: "serif",
              fontSize: "10pt",
            }}
          >
            <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
              To resist malicious, deceptive, fraudulent, or illegal actions
              directed at PC24/7 and to prosecute those responsible for those
              actions.
            </span>
          </li>
          <li
            style={{
              marginLeft: "27.6pt",
              paddingLeft: "8.4pt",
              fontFamily: "serif",
              fontSize: "10pt",
            }}
          >
            <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
              To verify user identity in limited circumstances (e.g., government
              ID verification).
            </span>
          </li>
          <li
            style={{
              marginLeft: "27.6pt",
              paddingLeft: "8.4pt",
              fontFamily: "serif",
              fontSize: "10pt",
            }}
          >
            <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
              For purposes that
            </span>
            <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
              &nbsp;
            </span>
            <em>
              <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
                do not
              </span>
            </em>
            <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
              &nbsp;
            </span>
            <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}>
              infer characteristics about individuals.
            </span>
          </li>
        </ul>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Non-Discrimination.</strong>&nbsp;California residents have
          the right not to receive discriminatory treatment by us for the
          exercise of their rights conferred by the CCPA.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Authorized Agent.</strong>&nbsp;Only you, or someone legally
          authorized to act on your behalf, may make a verifiable consumer
          request related to your personal information. You may also make a
          verifiable consumer request on behalf of your minor child. To
          designate an authorized agent, please contact us as set forth in
          “contact us “below.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Verification</strong>. To protect your privacy, we will take
          steps the following steps to verify your identity before fulfilling
          your request. When you make a request, we will ask you to provide
          sufficient information that allows us to reasonably verify you are the
          person about whom we collected personal information or an authorized
          representative, which may include asking you to answer questions
          regarding your account and use of our Services.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          If you are a California resident and would like to exercise any of
          your rights under the CCPA, please contact us as set forth in “contact
          us” below. We will process such requests in accordance with applicable
          laws.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>De-Identified Information</strong>. If we create or receive
          de-identified information, we will not attempt to reidentify such
          information, except to comply with applicable law.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>
            Refer-a-Friend and Similar Financial Incentive Programs
          </strong>
          . As described above in&nbsp;How We Use Your Personal Information
          ("Share Content with Friends"), we may offer referral programs or
          other incentivized data collection programs. For example, we may offer
          incentives to you such as discounts or promotional items or credit in
          connection with these programs, wherein you provide your personal
          information in exchange for a reward, or provide personal information
          regarding your friends (such as their email address) and receive
          rewards when they sign up to use our Services. (The referred party may
          also receive rewards for signing up via your referral.) These programs
          are entirely voluntary and allow us to grow our business and provide
          additional benefits to you. The value of your data to us depends on
          how you ultimately use our Services, whereas the value of the referred
          party's data to us depends on whether the referred party ultimately
          becomes a user and uses our Services. Said value will be reflected in
          the incentive offered in connection with each program.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Accessibility</strong>. This Privacy Policy uses
          industry-standard technologies and was developed in line with the
          World Wide Web Consortium's Web Content Accessibility Guidelines,
          version 2.1 . If you wish to print this policy, please do so from your
          web browser or by saving the page as a PDF.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>California Shine the Light.</strong>&nbsp;The California
          "Shine the Light" law permits users who are California residents to
          request and obtain from us once a year, free of charge, a list of the
          third parties to whom we have disclosed their personal information (if
          any) for their direct marketing purposes in the prior calendar year,
          as well as the type of personal information disclosed to those
          parties.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Right for minors to remove posted content.</strong>&nbsp;Where
          required by law, California residents under the age of 18 may request
          to have their posted content or information removed from the
          publicly-viewable portions of the Services by contacting us directly
          as set forth in Contact Us&nbsp;below or logging into their account
          and removing the content or information using our self-service tools.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          If you are a resident of Nevada, you have the right to opt-out of the
          sale of certain Personal Information to third parties who intend to
          license or sell that Personal Information. Please note that we do not
          currently sell your Personal Information as sales are defined in
          Nevada Revised Statutes Chapter 603A. To request that we do not sell
          your information in the future, you may contact us at{" "}
          <a
            href="mailto:support@pclive247.com"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>support@pclive247.com</span>
            </u>
          </a>
          &nbsp; with the subject line "Nevada Do Not Sell Request" and
          providing us with your name and the email address associated with your
          account. If you have any questions, please contact us as set forth
          in&nbsp;Contact Us&nbsp;below.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>CHILDREN'S INFORMATION</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          The Services are not directed to children under 13 (or other age as
          required by local law), and we do not knowingly collect personal
          information from children. As stated in our Terms of Service, you must
          be at least 18 years old in order to create an Account or use the App.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          If you are a parent or guardian and believe your child has uploaded
          personal information to our site without your consent, you may contact
          us as described in "Contact Us" below. If we become aware that a child
          has provided us with personal information in violation of applicable
          law, we will delete any personal information we have collected, unless
          we have a legal obligation to keep it, and terminate the child's
          account.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>OTHER PROVISIONS</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Third-Party Websites/Applications.</strong>&nbsp;The Services
          may contain links to other websites/applications and other
          websites/applications may reference or link to our Services. These
          third-party services are not controlled by us. We encourage our users
          to read the privacy policies of each website and application with
          which they interact. We do not endorse, screen or approve, and are not
          responsible for, the privacy practices or content of such other
          websites or applications. Providing personal information to
          third-party websites or applications is at your own risk.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Supervisory Authority</strong>. If you are located in the
          European Economic Area, Switzerland, the United Kingdom or Brazil, you
          have the right to lodge a complaint with a supervisory authority if
          you believe our processing of your personal information violates
          applicable law.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>Changes to our Privacy Policy</strong>. We may revise this
          Privacy Policy from time to time in our sole discretion. If there are
          any material changes to this Privacy Policy, we will notify you to the
          extent required by applicable law. You understand and agree that you
          will be deemed to have accepted the updated Privacy Policy if you
          continue to use our Services after the new Privacy Policy takes
          effect.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          <strong>CONTACT US</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          If you have any questions about our privacy practices or this Privacy
          Policy, or to exercise your rights as detailed in this Privacy Policy,
          please email us at&nbsp;
          <a
            href="mailto:support@pclive247.com"
            style={{ textDecoration: "none" }}
          >
            <u>
              <span style={{ color: "#0563c1" }}>support@pclive247.com</span>
            </u>
          </a>
          &nbsp; or contact us at:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>PC24/7, Inc.</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>
          1017 Tenby Rd
          <br />
          Berwyn, PA 19312
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
  
      </div>
    </div>
  );
};

export default Policy;
