import React from "react";
import "./TermsAndCondition.scss";
const Policy = () => {
  return (
    <div className="terms-container">
      <h4>Terms and Conditions</h4>
      <div className="contents">
      <span>
        These Terms and Conditions ("Terms") govern your use of the PC 24/7
        mobile application ("App") provided by PC247 Inc. By downloading,
        installing, accessing, or using the App, you agree to be bound by these
        Terms. If you do not agree to these Terms, please do not use the App.
      </span>
      <br />
      <br />
      <span>1. Acceptance of Terms</span>
      <br />
      <br />
      <span>
        By using the App, you agree to abide by these Terms and any updates or
        modifications that may be made from time to time. The Company reserves
        the right to modify these Terms without notice, and it is your
        responsibility to review these Terms regularly for changes. Your
        continued use of the App after any modifications will constitute your
        acceptance of the revised Terms.
      </span>
      <br />
      <br />
      <span>2. Use of the App</span>
      <br />
      <br />
      <span>
        2.1. Eligibility: You must be at least 13 years old to use the App. By
        using the App, you confirm that you meet this age requirement.
      </span>
      <br />
      <br />
      <span>
        2.2. Account Registration: To use certain features of the App, you may
        be required to create an account. You agree to provide accurate and
        up-to-date information during the registration process. You are
        responsible for maintaining the confidentiality of your account
        credentials and are liable for all activities that occur under your
        account.
      </span>
      <br />
      <br />
      <span>3. Auctions and Sales</span>
      <br />
      <br />
      <span>
        3.1. Auction Listings: PC 24/7 provides a platform for users to list
        sports and gaming cards for auction. By listing an item, you represent
        and warrant that you have the legal right to sell the item and that all
        information provided in the listing is accurate.
      </span>
      <br />
      <br />
      <span>
        3.2. Bidding: Users may bid on items listed in auctions. Bids are
        legally binding contracts. If you are the highest bidder when an auction
        ends, you are obligated to complete the purchase of the item at the
        winning bid price.
      </span>
      <br />
      <br />
      <span>
        3.3. Payment: Buyers are required to make payment for won auctions
        within the specified timeframe. Payment methods may be provided within
        the App and are subject to the terms of the payment service provider.
      </span>
      <br />
      <br />
      <span>4. Content and Conduct</span>
      <br />
      <br />
      <span>
        4.1. Prohibited Content: You agree not to post, upload, or transmit any
        content that is illegal, infringing, defamatory, or violates the rights
        of others. The Company reserves the right to remove or block access to
        any content that violates these Terms.
      </span>
      <br />
      <br />
      <span>
        4.2. User Conduct: You agree to use the App in a manner consistent with
        all applicable laws and regulations. Any conduct that disrupts or harms
        the App's functionality or reputation is strictly prohibited.
      </span>
      <br />
      <br />
      <span>5. Intellectual Property</span>
      <br />
      <br />
      <span>
        The App, including all content, trademarks, and intellectual property
        rights, is owned by the Company or its licensors. You may not reproduce,
        distribute, or modify any part of the App without the Company's express
        written consent.
      </span>
      <br />
      <br />
      <span>6. Privacy Policy</span>
      <br />
      <br />
      <span>
        Your use of the App is also governed by our Privacy Policy, which is
        incorporated into these Terms by reference. You can review our Privacy
        Policy here.
      </span>
      <br />
      <br />
      <span>7. Termination</span>
      <br />
      <br />
      <span>
        The Company reserves the right to terminate or suspend your account and
        access to the App at its discretion, with or without notice, for any
        violation of these Terms or for any other reason.
      </span>
      <br />
      <br />
      <span>8. Disclaimer of Warranties</span>
      <br />
      <br />
      <span>
        The App is provided "as is" and "as available." The Company makes no
        warranties, express or implied, regarding the App's accuracy,
        reliability, or availability.
      </span>
      <br />
      <br />
      <span>9. Limitation of Liability</span>
      <br />
      <br />
      <span>
        The Company shall not be liable for any indirect, incidental, special,
        consequential, or punitive damages arising out of or in connection with
        the App or these Terms.
      </span>
      <br />
      <br />
      <span>10. Governing Law</span>
      <br />
      <br />
      <span>
        These Terms shall be governed by and construed in accordance with the
        laws of Delaware
      </span>
      <br />
      <br />
      <span>11. Contact Information</span>
      <br />
      <br />
      <span>
        If you have any questions or concerns about these Terms or the App,
        please contact us at [Your Contact Information].
      </span>
      <br />
      <br />
      <span>
        By using the PC 24/7 App, you acknowledge that you have read,
        understood, and agreed to these Terms and Conditions.
      </span>
      </div>
    </div>
  );
};

export default Policy;
