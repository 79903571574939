import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const AlertBar = (props) => {
  const { open, handleClose,alertType, message } = props;

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={handleClose}
      autoHideDuration={5000}
    >
      <Alert onClose={handleClose} severity={alertType} sx={{ width: "100%" }}>
       {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertBar;
