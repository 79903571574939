import './Modal.scss'

import AddressSelect from "views/cards/address-select/AddAddressSelect";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import React from "react";

const Modals = (props) => {
  const {
    onClose,
    open,
    title,
    width,
    height,
    showAddressSelect = false,
    updateAddressDetails,
    allAddress,
    multiAddressError,
  } = props;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth < 700 ? "unset" : width ? width : 400,
    bgcolor: "background.paper",
    p: 4,
    padding: "15px",
    minHeight: height ? height : "150px",
    borderRadius: "5px",
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-mui-class-contents"
    >
      <Box sx={style}>
        <Grid container>
          <Grid item xs={7}>
            <h4>{title}</h4>
          </Grid>
          <Grid container xs={5} className="center-align">
            <Grid item xs={11}>
              {showAddressSelect && updateAddressDetails && (
                <div className="address-select-container">
                  <AddressSelect
                    updateAddressDetails={updateAddressDetails}
                    allAddress={allAddress}
                    isRequired
                  />
                  {multiAddressError ? (
                    <span className="error">
                      Please select a pickup address
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </Grid>
            <Grid item xs={1}>
              <CloseIcon
                onClick={() => onClose()}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
        </Grid>
        <hr style={{ margin: "10px 0 15px 0" }} />
        {props.children}
      </Box>
    </Modal>
  );
};

export default Modals;
