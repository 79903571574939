import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN

class VerifyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: "",
      isLoading: false,
      token:""
    };
    this.handleClick = this.handleClick.bind(this);
  }
  getToken = () => {
    return localStorage.getItem("accessToken");
  };

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token')
    this.setState({ stripe: await this.props.stripePromise ,token: urlToken});
  }
  async handleClick(event) {
    // Block native event handling.
    event.preventDefault();

    const { stripe } = this.state;

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    }

    this.setState({ isLoading: true });
    // Call your backend to create the Verificimport ationSession.
    const response = await fetch(`${API_DOMAIN}api/verify_identity/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    });
    const session = await response.json();
    this.setState({ isLoading: false });

    // Show the verification modal.
    const { error } = await stripe.verifyIdentity(session.data.client_secret);

    if (error) {
      console.log("[error]", error);
    } else {
      console.log("Verification submitted!");
      this.props.setVarified(true)
      localStorage.setItem("stripe_varified","yes")
    }
  }
  render() {
    const { stripe } = this.state;
    return (
      <>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 99999 }}
          open={this.state.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <button
          role="link"
          disabled={!stripe}
          onClick={this.handleClick}
          style={{
            padding: "15px 30px",
            border: "none",
            backgroundColor: "#1b76d1",
            color: "#ffffff",
            borderRadius: "10px",
          }}
        >
          Verify
        </button>
      </>
    );
  }
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  // "pk_test_51Mr1b2GYGLSBzolz1BdMp3xBu1K5O6JHExMJE7CjC4S6vNSocXri9L8dDzm2et92d21HeFSTgmsXcfkhVGaTdq7c008oebkuF9"
  process.env.REACT_APP_STRIPE_ID
);

const Stripe = (props) => {
  const {setVarified} = props;
  return <VerifyButton setVarified={setVarified} stripePromise={stripePromise} />;
};

export default Stripe;
