import React,{useState} from "react";
import Stripe from "./Stripe";

const style = {
  position: "relative",
  padding: 50,
  backgroundColor: "#ffffff",
  borderRadius: 10,
  zIndex: 999,
};
const Varify = () => {
  const [varified,setVarified] = useState(false)
  const stripe = localStorage.getItem("stripe_varified")
  

  return (
    <div style={style}>
    {varified 
    // || stripe=="yes"
    ?  <div>
      <h4>
      The verification process is in progress ,we will get back to you once completed
      </h4>
      <p style={{textAlign:'center'}}>
        You can close this browser and use the app.
      </p>
      </div>:
      <div>
      <h4>
        Before you begin, please be sure to have the following items readily
        available:
      </h4>
      <p>
        1. Government-issued ID (US, Canadian, or UK based Driver’s License,
        Identity Card, or Passport)
      </p>
      <p>2.Payment Method</p>
      <div className="text-center">
      <Stripe setVarified={setVarified} />
      </div>
    </div>}
    </div>
  );
};

export default Varify;
