import "./Radio.scss";
import * as React from "react";
import { BpRadio } from "./RadioIconDesign";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { useSelector } from "react-redux";

export default function CustomizedRadios(props) {
  const {
    label,
    data,
    onChange,
    value,
    contentAlign,
    isVerticalRadio,
    isDark,
  } = props;
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  return (
    <div
      className={`radio-component ${
        isDark || globalDarkMode ? "pclive-dark-mode" : ""
      }`}
    >
      <FormControl style={{ width: "100%" }}>
        <FormLabel id="demo-customized-radios" className="text-sm-medium-14">
          {label}
        </FormLabel>
        <RadioGroup
          defaultValue="female"
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
          style={{
            display: "flex",
            flexDirection: isVerticalRadio ? "column" : "row",
            justifyContent: contentAlign ? contentAlign : "space-between",
            marginLeft: isVerticalRadio ? "2px" : "",
            maxHeight: 300,
            overflowY: "auto",
            flexWrap: "nowrap",
          }}
          onChange={onChange}
          value={value}
        >
          {data && data.length
            ? data.map((radio) => {
                return (
                  <FormControlLabel
                    value={radio.value}
                    control={<BpRadio />}
                    label={
                      <span
                        className={`text-sm-medium-14  ${
                          radio.value == value ? "selected" : ""
                        }`}
                      >
                        {radio.label}
                      </span>
                    }
                  />
                );
              })
            : ""}
        </RadioGroup>
      </FormControl>
    </div>
  );
}
