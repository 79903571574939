import React, { useEffect, useState } from "react";
import "./Search.scss";

import { Button, IconButton, InputAdornment } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { ReactComponent as SearchIcon } from "../../../assets/img/search-white.svg";
import { ReactComponent as SearchCloseIcon } from "../../../assets/img/cross-white.svg";
import { useSelector } from "react-redux";
import FilterDropdown from "shared/filter/FilterDropdown";

const SearchInput = (props) => {
  const {
    icon,
    onCancle,
    value,
    viewIcon,
    onSearch,
    isSelect,
    setSelectSearch,
    radioValue,
    isDark,
    columns,
    showInputField = true,
    setShowInputField,
    source,
  } = props;
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const [placeholder, setPlaceholder] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("");

  useEffect(() => {
    columns && columns?.length === 1
      ? setPlaceholder(columns[0]?.placeholder)
      : setPlaceholder("");
  }, [columns]);

  useEffect(() => {
    if (!radioValue) {
      columns && columns?.length > 1 && setPlaceholder("");
      setSelectedColumn("");
    } else {
      const currentColumn = columns?.filter(
        (column) => column.value === radioValue
      )[0];
      if (currentColumn) {
        setPlaceholder(currentColumn?.placeholder);
        setSelectedColumn(currentColumn?.label);
      }
    }
  }, [columns, radioValue]);

  return (
    <div
      className={`search-input-container  ${
        globalDarkMode || isDark ? "pclive-dark-mode" : ""
      }`}
    >
      {showInputField && (
        <>
          {isSelect && columns && columns?.length > 1 && (
            <FilterDropdown
              title={selectedColumn ? selectedColumn : "Select Column"}
              list={columns}
              subtitle={"Select search type"}
              result={(val) => setSelectSearch(val)}
              type="radio"
              radioValue={radioValue}
              isVerticalRadio
              isSearch
            />
          )}
          <TextField
            {...props}
            id="outlined-size-small"
            label=""
            type="text"
            size="small"
            autoComplete="off"
            placeholder={placeholder ? placeholder : "Search here"}
            className="text-sm-medium-14"
            style={{
              borderRadius:
                isSelect && columns && columns?.length > 1
                  ? "0px 8px 8px 0px"
                  : "8px",
            }}
            InputProps={{
              startAdornment: icon && (
                <InputAdornment position="start">{icon}</InputAdornment>
              ),
              endAdornment: value && (
                <InputAdornment position="end">
                  {
                    <IconButton onClick={onCancle} edge="end">
                      <CloseIcon className="close" />
                    </IconButton>
                  }
                </InputAdornment>
              ),
            }}
          />
        </>
      )}
      {viewIcon && (
        <Button
          className="search-action"
          sx={{
            margin: showInputField ? "0 0 0 3px" : "0",
          }}
          startIcon={
            source === "requests" ? (
              showInputField && !value ? (
                <SearchCloseIcon />
              ) : (
                <SearchIcon />
              )
            ) : (
              <SearchIcon />
            )
          }
          onClick={() => {
            source === "requests"
              ? showInputField && value
                ? onSearch()
                : setShowInputField((prev) => !prev)
              : onSearch();
          }}
        ></Button>
      )}
    </div>
  );
};
export default SearchInput;
