import React, { useState } from "react";
import "./OTP.scss";
import OtpInput from "react-otp-input";
import { Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { userAuth } from "actions/HomeAction";
import { useHistory } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "shared/alert/Alert";
import { resendOtp } from "actions/HomeAction";
const OTPPage = () => {
  // State to store OTP inputs
  const [otp, setOtp] = useState("");
  const [openAlert, setOpenAlert] = useState({
    open: false,
    type: "",
    message: "",
  });
const useremail = localStorage.getItem("userEmail")
const history = useHistory();
const dispatch = useDispatch();
const isLoading = useSelector((state) => state.home.isLoading);


  // Event handler for OTP input changes
  const handleChange = (code) => setOtp(code);
  const onSubmit = () => {
    const data = {
        email:useremail,
        otp
    }
    const handleSuccess = (body) => {
  

      if(body.success){
        setOpenAlert({
          ...openAlert,
          open: true,
          message: body.message,
          type: "success",
        });
        localStorage.setItem("userEmail",data.email)
        setTimeout(() => {
          history.push("/admin/dashboard");
        }, 1000);
      }else{
        
      }
      };
      const handleFail = (body) => {
        setOpenAlert({
          ...openAlert,
          open: true,
          message: body.message,
          type: "error",
        });
      };
    dispatch(userAuth(data, handleSuccess, handleFail));

  };

  const handleResendOtp = ()=>{
    const data ={
      email:useremail
    }
    const handleSuccess = (body) => {
      if(body.success){
        setOpenAlert({
          ...openAlert,
          open: true,
          message: body.message,
          type: "success",
        });
  
      }
      };
      const handleFail = (body) => {
        setOpenAlert({
          ...openAlert,
          open: true,
          message: body.message,
          type: "error",
        });
      };
    dispatch(resendOtp(data, handleSuccess, handleFail));

  }
  return (
    <div className="otp-container">
            <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Alert
        message={openAlert.message}
        open={openAlert.open}
        handleClose={() => setOpenAlert({ ...openAlert, open: false })}
        alertType={openAlert.type}
      />
      <h3>Verify account</h3>
      <p>We have sent you the varification code to <br/><strong>{useremail}</strong></p>
      <OtpInput
        value={otp}
        renderInput={(props) => <input {...props} type="number"/>}
        onChange={handleChange}
        numInputs={6}
        separator={<span style={{ width: "8px" }}></span>}
        isInputNum={true}
        shouldAutoFocus={true}
        inputStyle={{
          border: "1px solid transparent",
          borderRadius: "8px",
          width: "54px",
          height: "54px",
          fontSize: "12px",
          color: "#000",
          fontWeight: "400",
          caretColor: "blue",
          marginRight: 10,
          boxShadow: "rgba(0, 0, 0, 0.4) 0px 1px 2px 0px",
        }}
        focusStyle={{
          border: "1px solid #CFD3DB",
          outline: "none",
        }}
      />
      <p className="resend">Didn't receive an OTP? <strong onClick={handleResendOtp}>Resend OTP</strong></p>
      <div className="submit">
        <Button
          onClick={onSubmit}
          color="primary"
          type="button"
          className="action"
          disabled={otp.length!=6}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default OTPPage;
