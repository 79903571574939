import React from "react";
import { Input } from "reactstrap";
import './SelectInput.scss'
const SelectInput = (props)=>{
    return(
        <Input
          type={"select"}
          {...props}
          className="custom-select"
        >
            {props.children}
        </Input>
    )
}

export default SelectInput