import "./Address.scss";

import { Card, CardHeader, FormGroup, Row, Table } from "reactstrap";
import { City, Country, State } from "country-state-city";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddAddress from "./add-address/AddAddress";
import AddIcon from "@mui/icons-material/Add";
import AlertBar from "shared/alert/Alert";
import { ReactComponent as ArrowDropDownIcon } from "../../assets/img/Descending Order.svg";
import { ReactComponent as ArrowDropUpIcon } from "../../assets/img/Ascending order.svg";
import Backdrop from "@mui/material/Backdrop";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import DilogBox from "shared/dilog/DilogBox";
import EditIcon from "@mui/icons-material/Edit";
import SearchInput from "../../shared/input/search/Search";
import { ReactComponent as SortIcon } from "../../assets/img/ascending.svg";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import { addAddress } from "../../actions/HomeAction";
import { addressActivation } from "../../actions/HomeAction";
import { continantalUs } from "./continantalStates.js";
import { deleteAddress } from "../../actions/HomeAction";
import { editAddress } from "../../actions/HomeAction";
import { getAddress } from "../../actions/HomeAction";

const Users = () => {
  const INITIAL_STATE = {
    id: 0,
    line1: "",
    city: "",
    state: "",
    country: "US",
    postal_code: "",
    is_primary: false,
    address_type: "work",
    address_name: "",
  };
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.home.isLoading);
  const getAddressDetails = useSelector((state) => state.home.getAddress);
  const [addressOpen, setAddressOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isDelete, setDelete] = useState(false);
  const [allState, setAllState] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [sortData, setSortData] = useState([]);
  const [addType, setAddType] = useState(null);
  const [currentSort, setCurrentSortData] = useState("");
  const [addressState, setAddress] = useState(INITIAL_STATE);
  const [isResetClicked, setIsResetClicked] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [selectSearch, setSelectSearch] = useState("search_text");
  const [openAlert, setOpenAlert] = useState({
    open: false,
    type: "success",
    message: "",
  });
  const countries = Country.getAllCountries().filter((f) => f.isoCode == "US");

  useEffect(() => {
    dispatch(getAddress(true, sortData, searchQuery, addType));
  }, [sortData, addType, isResetClicked]);

  const onChangeInput = (val, type) => {
    let error = [...errors];
    let address = { ...addressState };
    let result = error.filter((f) => f !== type);
    setErrors(result);
    if (type == "country") {
      address.city = "";
      address.state = "";
      setAllCity([]);
    }
    if (type == "state") {
      address.city = "";
    }
    address[type] = val;
    setAddress(address);
  };
  useEffect(() => {
    if (addressState.country != "") {
      let state = State.getStatesOfCountry(addressState.country);
      let continentalUsa = state.filter((f) =>
        continantalUs.includes(f.isoCode)
      );
      setAllState(continentalUsa);
    } else {
      setAllState([]);
    }
    if (addressState.state != "") {
      let city = City.getCitiesOfState(
        addressState.country,
        addressState.state
      );
      setAllCity(city);
    } else {
      setAllCity([]);
    }
  }, [addressState]);
  const isValid = () => {
    let error = [];

    if (addressState.line1 === "") {
      error.push("line1");

      setErrors(error);
    }
    if (
      addressState.postal_code === "" ||
      !addressState.postal_code.toString().match(/^[0-9]{5}$/)
    ) {
      error.push("postal_code");

      setErrors(error);
    }

    onSave(error);
  };
  const onSave = (error) => {
    if (error.length === 0) {
      const handleSuccess = (body) => {
        setOpenAlert({
          ...openAlert,
          open: true,
          message: body.message,
          type: "success",
        });
        if (body.success) {
          setIsEdit(false);
          setAddressOpen(false);
          setErrors([]);
          dispatch(getAddress(true, sortData));
        }
      };
      const handleFail = (body) => {
        setOpenAlert({
          ...openAlert,
          open: true,
          message: body.message,
          type: "warning",
        });
      };
      if (!isEdit) {
        const data = { ...addressState };
        delete data.id;
        dispatch(addAddress(data, handleSuccess, handleFail));
      } else {
        const data = {
          address_id: addressState.id,
          ...addressState,
        };
        dispatch(editAddress(data, handleSuccess, handleFail));
      }
    }
  };

  const onEdit = (address) => {
    setAddressOpen(true);
    setAddress(address);
    setIsEdit(true);
  };

  const onDelete = () => {
    const handleSuccess = () => {
      setDelete(false);
      setOpenAlert({
        ...openAlert,
        open: true,
        message: "Address deleted successfully!!",
        type: "success",
      });
      dispatch(getAddress(true, sortData));
    };
    const handleFail = (error) => {
      setDelete(false);
      setOpenAlert({
        ...openAlert,
        open: true,
        message: error.message,
        type: "error",
      });
    };
    dispatch(deleteAddress(addressState.id, handleSuccess, handleFail));
  };

  const onSortData = (val) => {
    if (sortData.length === 0 || currentSort != val) {
      setSortData([val]);
    } else if (sortData[0] === val) {
      setSortData([`-${val}`]);
    } else {
      setSortData([]);
    }
    setCurrentSortData(val);
  };

  const onSearch = () => {
    onReset(true);
  };
  
  const onReset = (isSearch) => {
    if (!isSearch) {
      setSearchQuery("");
      setSortData([]);
      setAddType(null);
    }
    setIsResetClicked(!isResetClicked);
  };

  const onStatusChange = (address) => {
    const data = {
      address_id: address.id,
      status: !address.status,
    };
    const handleSuccess = (body) => {
      dispatch(getAddress(true));
      setOpenAlert({
        ...openAlert,
        open: true,
        message: `Address ${
          address.status ? "deactivated" : "activated"
        } successfully !!`,
        type: "success",
      });
    };
    const handleFail = (error) => {
      setOpenAlert({
        ...openAlert,
        open: true,
        message: error.message,
        type: "error",
      });
    };
    dispatch(addressActivation(data, handleSuccess, handleFail));
  };
  return (
    <div className="address-container">
      <AlertBar
        message={openAlert.message}
        open={openAlert.open}
        handleClose={() => setOpenAlert({ ...openAlert, open: false })}
        alertType={openAlert.type}
      />
      <DilogBox
        message={"Are you sure you want to delete this address?"}
        onSuccess={onDelete}
        onCancel={() => setDelete(false)}
        successText={"Yes"}
        cancelText={"No"}
        open={isDelete}
        isDelete={true}
      />
      <div className="text-right mb-3">
        <Button
          variant="contained"
          onClick={() => {
            setAddressOpen(true);
            setAddress(INITIAL_STATE);
            setIsEdit(false);
          }}
        >
          <AddIcon
          // className="icon"
          />
          Add Address
        </Button>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AddAddress
        INITIAL_STATE={INITIAL_STATE}
        errors={errors}
        setAddressOpen={setAddressOpen}
        addressOpen={addressOpen}
        addressState={addressState}
        onChangeInput={onChangeInput}
        countries={countries}
        allState={allState}
        allCity={allCity}
        isValid={isValid}
        isEdit={isEdit}
        setErrors={setErrors}
        setAddress={setAddress}
      />
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <h3 className="mb-0">Address</h3>
              <div className="top-container">
                <Box className="search-box flex">
                  <FormGroup className="form-class">
                    <SearchInput
                      viewIcon
                      onChange={(e) => setSearchQuery(e.target.value)}
                      value={searchQuery}
                      onSearch={onSearch}
                      setSelectSearch={(val) => setSelectSearch(val)}
                      radioValue={selectSearch}
                      onCancle={() => onReset(false)}
                      isSearch={true}
                      columns={[
                        {
                          label: "Address",
                          value: "address",
                          placeholder: "Eg: North Pole",
                        },
                      ]}
                    />
                  </FormGroup>
                </Box>
                <Button
                  className="button"
                  variant="text"
                  onClick={() => onReset(false)}
                >
                  Reset All
                </Button>
              </div>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th
                    scope="col"
                    onClick={() => {
                      onSortData("address_name");
                    }}
                  >
                    Address Name{" "}
                    {sortData.length == 0 ? (
                      <SortIcon className="sort-icon" />
                    ) : sortData[0] === "address_name" ? (
                      <ArrowDropUpIcon className="sort-icon-dark" />
                    ) : sortData[0] === "-address_name" ? (
                      <ArrowDropDownIcon className="sort-icon-dark" />
                    ) : (
                      <SortIcon className="sort-icon" />
                    )}
                  </th>
                  <th
                    scope="col"
                    onClick={() => {
                      onSortData("line1");
                    }}
                  >
                    Address{" "}
                    {sortData.length == 0 ? (
                      <SortIcon className="sort-icon" />
                    ) : sortData[0] === "line1" ? (
                      <ArrowDropUpIcon className="sort-icon-dark" />
                    ) : sortData[0] === "-line1" ? (
                      <ArrowDropDownIcon className="sort-icon-dark" />
                    ) : (
                      <SortIcon className="sort-icon" />
                    )}
                  </th>
                  <th scope="col">Country</th>
                  <th scope="col">State</th>
                  <th
                    scope="col"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onSortData("city");
                    }}
                  >
                    City{" "}
                    {sortData.length == 0 ? (
                      <SortIcon className="sort-icon" />
                    ) : sortData[0] === "city" ? (
                      <ArrowDropUpIcon className="sort-icon-dark" />
                    ) : sortData[0] === "-city" ? (
                      <ArrowDropDownIcon className="sort-icon-dark" />
                    ) : (
                      <SortIcon className="sort-icon" />
                    )}
                  </th>
                  <th
                    scope="col"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onSortData("postal_code");
                    }}
                  >
                    Postal code{" "}
                    {sortData.length == 0 ? (
                      <SortIcon className="sort-icon" />
                    ) : sortData[0] === "postal_code" ? (
                      <ArrowDropUpIcon className="sort-icon-dark" />
                    ) : sortData[0] === "-postal_code" ? (
                      <ArrowDropDownIcon className="sort-icon-dark" />
                    ) : (
                      <SortIcon className="sort-icon" />
                    )}
                  </th>
                  <th scope="col">Primary</th>
                  <th
                    scope="col"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onSortData("address_type");
                    }}
                  >
                    Address type{" "}
                    {sortData.length == 0 ? (
                      <SortIcon className="sort-icon" />
                    ) : sortData[0] === "address_type" ? (
                      <ArrowDropUpIcon className="sort-icon-dark" />
                    ) : sortData[0] === "-address_type" ? (
                      <ArrowDropDownIcon className="sort-icon-dark" />
                    ) : (
                      <SortIcon className="sort-icon" />
                    )}
                  </th>
                  <th scope="col">Actions</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {getAddressDetails && getAddressDetails.length ? (
                  getAddressDetails.map((address, index) => {
                    return (
                      <tr>
                        <td>
                          {address.address_name
                            ? address.address_name
                            : address.address_type}
                        </td>
                        <td>{address.line1}</td>
                        <td>
                          {Country.getCountryByCode(address.country).name}
                        </td>
                        <td>{State.getStateByCode(address.state).name}</td>
                        <td>{address.city}</td>
                        <td>{address.postal_code}</td>
                        <td>{address.is_primary ? "YES" : "NO"}</td>
                        <td
                          className="capital-text"
                          style={{ textTransform: "capitalize" }}
                        >
                          {address.address_type}
                        </td>
                        <td>
                          <div className="actionIconsContainer">
                            <Tooltip placement="top" title="Edit">
                              <EditIcon
                                className="icon"
                                onClick={() => onEdit(address)}
                              />
                            </Tooltip>
                            <Tooltip
                              placement="top"
                              title={
                                getAddressDetails.length == 1
                                  ? "Can not delete the only address."
                                  : "Delete"
                              }
                            >
                              <DeleteIcon
                                className="icon"
                                style={
                                  getAddressDetails.length == 1
                                    ? {
                                        color: "#c0c0c0",
                                        cursor: "default",
                                      }
                                    : {}
                                }
                                onClick={() => {
                                  if (getAddressDetails.length > 1) {
                                    setAddress(address);
                                    setDelete(true);
                                  }
                                }}
                              />
                            </Tooltip>
                          </div>
                        </td>
                        <td>
                          <Switch
                            color="success"
                            checked={
                              address.status !== undefined
                                ? address.status
                                : false
                            }
                            onChange={() => onStatusChange(address)}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className="align-center">
                      No data found!
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card>
        </div>
      </Row>
    </div>
  );
};

export default Users;
