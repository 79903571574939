//Dashboard

export const TOTAL_EARNING_SELLER =
  "Showing total earnings (Bid price - Tax - PCLive Commission - payment processing fee)";
export const TOTAL_EARNING_ADMIN =
  "Showing total earnings on selected timeline";
export const AUTHENTIC = "Authentic";
export const FAQ_1 = "Delete Account";
export const FAQ_1_DETAILS =
  "How do I delete my account? To delete your account, you can navigate to Delete Account, under the User Profile > Settings tab, inside the PCLIVE247 App";
export const REVERT_CONFIRMATION_TITLE = "Convert to personal card?";
export const REVERT_CONFIRMATION_SUCCESS_BUTTON_TEXT =
  "Yes, convert to personal card";
export const REVERT_CONFIRMATION_DESCRIPTION_SINGLE =
  "Are you sure you want to convert the corporate card to personal card?";
export const REVERT_CONFIRMATION_DESCRIPTION_BULK_CSV =
  "Are you sure you want to convert all the corporate cards to personal cards?";

export const showData = (data) => {
  switch (data) {
    case "PRE_TRANSIT":
      return "PROCESSING";
    case "TRANSIT":
      return "SHIPPED";
    case "DELIVERED":
      return "DELIVERED";
    case "RETURNED":
      return "RETURNED";
    case "CANCELLED":
      return "CANCELLED";
    case "FAILURE":
      return "FAILED";
    case "ORDER_RECEIVED":
      return "ORDER RECEIVED";
    case "UNKNOWN":
      return "ORDER RECEIVED";
    default:
      return data;
  }
};
