import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
  padding: "20px",
  borderRadius: "5px",
};
const DilogBox = (props) => {
  const {onSuccess,onCancel,message,successText,cancelText,open , isDelete} = props;

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
      open={open}
      className="back-Drop-modal"
    >
      <Box sx={style}>
        <h4> {message}</h4>
        <div className="text-right ">
          <Button
            variant="contained"
            onClick={(e) => onSuccess()}
            size="sm"
            className="button"
          >
            {successText}
          </Button>
          <Button
            className="button"
            variant="outlined"
            style={{ marginLeft: 10 }}
            onClick={onCancel}
          >
            {cancelText}
          </Button>
        </div>
      </Box>
    </Backdrop>
  );
};

export default DilogBox;
