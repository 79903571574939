import FAQ from "views/terms-mobile/FAQ";
import Login from "views/Login.js";
import OTPPage from "views/otp/OTP";
import Policy from "views/terms-mobile/PrivacyPolicy";
import Register from "views/register/Register";
import TermsAndCondition from "views/terms-mobile/TermsAndCondition";
import Varify from "views/stripe/Varify.js";

var routes = [
    {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/varify",
    name: "Stripe",
    icon: "ni ni-key-25 text-info",
    component: Varify,
    layout: "/auth"
  },
  {
    path: "/terms",
    name: "Terms and Condition",
    icon: "ni ni-key-25 text-info",
    component: TermsAndCondition,
    layout: "/auth"
  },
  {
    path: "/policy",
    name: "Privacy Policy",
    icon: "ni ni-key-25 text-info",
    component: Policy,
    layout: "/auth"
  },
  {
    path: "/otp",
    name: "OTP",
    icon: "ni ni-key-25 text-info",
    component: OTPPage,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-key-25 text-info",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/faq",
    name: "FAQ",
    icon: "ni ni-key-25 text-info",
    component: FAQ,
    layout: "/auth"
  }

];
export default routes;
