import React, { useState } from "react";
import "./Register.scss";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  CardHeader,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { userAuth } from "actions/HomeAction";
import { useHistory } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "shared/alert/Alert";
import { resendOtp } from "actions/HomeAction";
import { registerUser } from "actions/HomeAction";
const Register = () => {
  // State to store OTP inputs
  const [formData, setFormDate] = useState({
    username: "",
    email: "",
    phone: "",
  });
  const [errors, setErrors] = useState([]);
  const [isSubmitted, setSubmitted] = useState(false);

  const [openAlert, setOpenAlert] = useState({
    open: false,
    type: "",
    message: "",
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.home.isLoading);

  // Event handler for OTP input changes

  const isValid = () => {
    let error = [];

    if (formData.username.trim() === "") {
      error.push("username");

      setErrors(error);
    }
    if (
      formData.email === "" ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
    ) {
      error.push("email");

      setErrors(error);
    }
    onSubmit(error);
  };

  const onChangeInput = (type, val) => {
    let data = { ...formData };
    let error = [...errors];
    if (error.includes(type)) {
      let result = error.filter((f) => f !== type);
      setErrors(result);
    }
    setFormDate({ ...data, [type]: val });
  };

  const onSubmit = (error) => {
    if (error.length === 0) {
 let data =  {...formData}
 if(data.phone ==""){
  delete data.phone
 }
   
      const handleSuccess = (body) => {
         
          setOpenAlert({
            ...openAlert,
            open: true,
            message: "Registered successfully !!",
            type: "success",
          });
        setSubmitted(true)

        
      };
      const handleFail = (body) => {
        setOpenAlert({
          ...openAlert,
          open: true,
          message: body.message,
          type: "error",
        });
      };
      dispatch(registerUser(data, handleSuccess, handleFail));
    }
  };

  return (
    <div className="register-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Alert
        message={openAlert.message}
        open={openAlert.open}
        handleClose={() => setOpenAlert({ ...openAlert, open: false })}
        alertType={openAlert.type}
      />
      {!isSubmitted ? (
        <div>
          <h3>Please fill out the form to register</h3>
          <Form role="form">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Username *"
                  type="text"
                  autoComplete="new-email"
                  value={formData.username}
                  onChange={(e) => onChangeInput("username", e.target.value)}
                />
              </InputGroup>
              {errors.includes("username") ? (
                <span style={{ color: "red", fontSize: 12 }}>
                  Please enter username
                </span>
              ) : (
                ""
              )}
            </FormGroup>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email *"
                  type="email"
                  autoComplete="new-email"
                  value={formData.email}
                  onChange={(e) => onChangeInput("email", e.target.value)}
                />
              </InputGroup>
              {errors.includes("email") ? (
                <span style={{ color: "red", fontSize: 12 }}>
                  Please enter valid email ID
                </span>
              ) : (
                ""
              )}
            </FormGroup>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-mobile-button" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Phone"
                  type="text"
                  autoComplete="new-email"
                  value={formData.phone}
                  onChange={(e) => onChangeInput("phone", e.target.value)}
                />
              </InputGroup>
              {errors.includes("phone") ? (
                <span style={{ color: "red", fontSize: 12 }}>
                  Please enter valid phone
                </span>
              ) : (
                ""
              )}
            </FormGroup>
          </Form>
          <div className="submit">
            <Button
              onClick={isValid}
              color="primary"
              type="button"
              className="action"
            >
              Submit
            </Button>
          </div>
        </div>
      ) : (
        <div className="thank-you">
          <div className="text-center">
            <span className="ni ni-check-bold"></span>
          </div>
          <h2>Thank you !</h2>
          <p>Your data has been saved and,<br/> Your response will be submitted.</p>
        </div>
      )}
    </div>
  );
};

export default Register;
