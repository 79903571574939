import "./AddAddress.scss";

import { FormGroup, Input } from "reactstrap";

import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Modal from "../../../shared/modal/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";
import SelectInput from "shared/input/SelectInput";
import { Switch } from "@mui/material";

const AddAddress = (props) => {
  const {
    INITIAL_STATE,
    errors,
    setAddressOpen,
    addressOpen,
    addressState,
    onChangeInput,
    countries,
    allState,
    allCity,
    isValid,
    isEdit,
    setAddress,
    setErrors
  } = props;
  return (
    <div>
      <Modal
        title={isEdit ? "Edit Address" : "Add Address"}
        onClose={() => {
          setAddressOpen(false);
          setAddress(INITIAL_STATE);
          setErrors([]);

        }}
        open={addressOpen}
        width={500}
      >
        <div className="add-address-container">
        <FormGroup>
            <label className="form-control-label" htmlFor="input-first-name">
              Address Name
            </label>
            <Input
              className="form-control-alternative"
              id="input-postal-code"
              placeholder="Address Name"
              type="text"
              onChange={(e) => onChangeInput(e.target.value, "address_name")}
              value={addressState.address_name}
              maxLength={100}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="input-first-name">
              Address line 1
            </label>
            <Input
              className="form-control-alternative"
              id="input-postal-code"
              placeholder="Address line 1"
              type="text"
              onChange={(e) => onChangeInput(e.target.value, "line1")}
              value={addressState.line1}
              maxLength={100}
            />
            {errors.includes("line1") ? (
              <span className="error">Please enter address</span>
            ) : (
              ""
            )}
          </FormGroup>
          <div className="form-container">
            <FormGroup className="form-group-content">
              <label className="form-control-label" htmlFor="input-first-name">
                Country
              </label>
              <SelectInput
                type={"select"}
                onChange={(e) => onChangeInput(e.target.value, "country")}
                value={addressState.country}
                disabled
              >
                <option value={""}>Select Country</option>

                {countries && countries.length
                  ? countries.map((country) => {
                      return (
                        <option value={country.isoCode}>{country.name}</option>
                      );
                    })
                  : ""}
              </SelectInput>
            </FormGroup>
            <FormGroup className="form-group-content">
              <label className="form-control-label" htmlFor="input-first-name">
                State
              </label>
              <SelectInput
                type={"select"}
                onChange={(e) => onChangeInput(e.target.value, "state")}
                value={addressState.state}
              >
                <option value={""}>Select state</option>

                {allState && allState.length
                  ? allState.map((state) => {
                      return (
                        <option value={state.isoCode}>{state.name}</option>
                      );
                    })
                  : ""}
              </SelectInput>
            </FormGroup>
          </div>
          <div className="form-container">
            <FormGroup className="form-group-content">
              <label className="form-control-label" htmlFor="input-first-name">
                City
              </label>
              <SelectInput
                type={"select"}
                onChange={(e) => onChangeInput(e.target.value, "city")}
                value={addressState.city}
              >
                <option value={""}>Select city</option>
                {allCity && allCity.length
                  ? allCity.map((city) => {
                      return <option value={city.isoCode}>{city.name}</option>;
                    })
                  : ""}
              </SelectInput>
              {errors.includes("postal_code") ? (
                <span className="error">&nbsp;</span>
              ) : (
                ""
              )}
            </FormGroup>
            <FormGroup className="form-group-content">
              <label className="form-control-label" htmlFor="input-first-name">
                Postal code
              </label>
              <Input
                className="form-control-alternative"
                placeholder="Postal"
                type="number"
                onChange={(e) => onChangeInput(e.target.value, "postal_code")}
                value={addressState.postal_code}
                maxLength={10}
              />
              {errors.includes("postal_code") ? (
                <span className="error">Please enter valid postal code</span>
              ) : (
                ""
              )}
            </FormGroup>
          </div>
          <div className="form-container">
            <div className="form-group-content">
              <label className="form-control-label" htmlFor="input-first-name">
                Address type
              </label>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                row
                onChange={(e) => onChangeInput(e.target.value, "address_type")}
                value={addressState.address_type}
              >
                <FormControlLabel
                  value="work"
                  control={<Radio />}
                  label="Work"
                />
                <FormControlLabel
                  value="home"
                  control={<Radio />}
                  label="Home"
                />
              </RadioGroup>
            </div>
            <div className="form-group-content">
              <label className="form-control-label" htmlFor="input-first-name">
                Is your address primary?
              </label>
              <div>
                <Switch
                  onChange={(e) =>
                    onChangeInput(e.target.checked, "is_primary")
                  }
                  checked={addressState.is_primary}
                />
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            onClick={isValid}
            fullWidth
            disabled={
              addressState.line1 == "" ||
              addressState.postal_code == "" ||
              addressState.country == "" ||
              addressState.city == "" ||
              addressState.state == ""
            }
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AddAddress;
