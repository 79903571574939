import "./AddAddressSelect.scss";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormControlLabel, RadioGroup } from "@mui/material";
import AddressDropDown from "../../../shared/addressDropdown/AddressDropdown";
import { BpRadio } from "../../../shared/radio/RadioIconDesign";
import CustomTabs from "../../../components/Tabs/CustomTabs.jsx";
import { TabPanel } from "@mui/lab";
import AlertBar from "shared/alert/Alert";

const AddressSelect = ({
  isEdit,
  allAddress,
  updateAddressDetails,
  showSellerAddress,
  sellerAddress,
  addressId,
  isCorporateCard,
  isDisabled,
  isRequired,
  adminCsvData,
  setAdminCsvData,
}) => {
  const dispatch = useDispatch();
  const [openlist, setOpenList] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [currentValue, setCurrentValue] = useState({
    label: "",
    value: "",
  });
  const [isCorporate, setIsCorporate] = useState(isCorporateCard);
  const [addressList, setAddressList] = useState(allAddress);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const tabData =
    showSellerAddress || isEdit
      ? [
          { title: `Corporate`, value: 1 },
          { title: "Seller", value: 2 },
        ]
      : [{ title: "Corporate", value: 1 }];

  useEffect(() => {
    setAddressList(allAddress);
    if (currentTab === 2 && (showSellerAddress || isEdit)) {
      setAddressList(sellerAddress);
    }
  }, [allAddress, showSellerAddress, isEdit, sellerAddress]);

  useEffect(() => {
    setCurrentValue({
      label: "",
      value: "",
    });
    setAdminCsvData &&
      setAdminCsvData((prev) => {
        return {
          ...prev,
          address_id: "",
          is_corporate: false,
        };
      });
    setCurrentTab(1);
  }, [adminCsvData?.seller, dispatch, setAdminCsvData]);

  useEffect(() => {
    function handleClickOutside(event) {
      const dropdownDiv = document.getElementById("anchor");
      if (
        dropdownDiv &&
        (event.target === dropdownDiv || dropdownDiv.contains(event.target))
      ) {
        // Click is inside the dropdown, don't close it
        return;
      }
      setOpenList(false);
    }

    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (addressId) {
      let is_corporate;
      let selectedAddress = allAddress?.filter(
        (address) => address?.id === addressId
      );
      is_corporate = true;
      setIsCorporate(true);
      if (
        sellerAddress?.length &&
        selectedAddress &&
        !selectedAddress?.length
      ) {
        selectedAddress = sellerAddress?.filter(
          (address) => address?.id === addressId
        );
        is_corporate = false;
        setIsCorporate(false);
      }
      if (selectedAddress && selectedAddress?.length) {
        setCurrentValue({
          label: `${
            is_corporate ? "Corporate Address :" : "Seller Address :"
          } ${
            selectedAddress[0]?.address_name
              ? selectedAddress[0]?.address_name
              : selectedAddress[0]?.address_type
          } : ${selectedAddress[0]?.line1}. ${selectedAddress[0]?.city}. ${
            selectedAddress[0]?.postal_code
          }`,
          value: addressId,
        });
      }
    }
  }, [addressId, allAddress, sellerAddress, isCorporate]);

  useEffect(() => {
    if (currentTab === 2) {
      setAddressList(sellerAddress);
    } else {
      setAddressList(allAddress);
    }
  }, [allAddress, currentTab, dispatch, sellerAddress, tabData?.length]);

  useEffect(() => {
    if (currentValue.value) {
      let is_corporate;
      let selectedAddress = allAddress?.filter(
        (address) => address.id === currentValue.value
      );
      is_corporate = true;
      setIsCorporate(true);
      if (selectedAddress && !selectedAddress.length) {
        selectedAddress = sellerAddress?.filter(
          (address) => address.id === currentValue.value
        );
        is_corporate = false;
        setIsCorporate(false);
      }
      selectedAddress &&
        selectedAddress?.length &&
        updateAddressDetails(selectedAddress, is_corporate);
      setOpenList(false);
    }
  }, [currentValue.value]);

  return (
    <div className="input-address-select">
      <AlertBar
        message={openAlert.message}
        open={openAlert.open}
        handleClose={() => setOpenAlert({ ...openAlert, open: false })}
        alertType={openAlert.type}
      />
      <label class="form-control-label" htmlFor="address">
        {`Pickup Address ${isRequired ? "*" : ""}`}
      </label>
      <AddressDropDown
        inputProps={{ readOnly: true }}
        onClick={() =>
          isDisabled
            ? setOpenAlert({
                ...openAlert,
                open: true,
                message: "Pickup address cannot be changed",
                type: "error",
              })
            : setOpenList(!openlist)
        }
        data={[currentValue]}
        value={currentValue.value}
        placeholder="Select Pickup Address"
        disabled={isDisabled}
      />
      {openlist && (
        <div className="dropdown-select-menu" id="anchor">
          {tabData.length > 1 ? (
            <CustomTabs
              tabData={tabData}
              value={currentTab}
              handleChange={(e, newval) => setCurrentTab(newval)}
            >
              {tabData?.map((tab, index) => (
                <TabPanel
                  key={index}
                  value={tab?.value}
                  sx={{
                    padding: "12px 0 0 0",
                  }}
                >
                  <RadioGroup
                    className="radio-group"
                    name="controlled-radio-buttons-group"
                  >
                    {addressList &&
                      addressList.length &&
                      addressList.map((address) => {
                        return (
                          <FormControlLabel
                            value={address.id}
                            name="radio"
                            checked={currentValue.value == address.id}
                            control={
                              <BpRadio
                                onClick={() =>
                                  setCurrentValue({
                                    label: `${
                                      currentTab == 1
                                        ? "Corporate Address :"
                                        : "Seller Address :"
                                    } ${
                                      address?.address_name
                                        ? address?.address_name
                                        : address?.address_type
                                    } : ${address?.line1}. ${address?.city}. ${
                                      address?.postal_code
                                    }`,
                                    value: address.id,
                                  })
                                }
                              />
                            }
                            label={
                              <span
                                className="address"
                                onClick={() =>
                                  setCurrentValue({
                                    label: `${
                                      currentTab == 1
                                        ? "Corporate Address :"
                                        : "Seller Address :"
                                    } ${
                                      address?.address_name
                                        ? address?.address_name
                                        : address?.address_type
                                    } : ${address?.line1}. ${address?.city}. ${
                                      address?.postal_code
                                    }`,
                                    value: address.id,
                                  })
                                }
                              >
                                <span className="addressName">
                                  {address?.address_name
                                    ? address?.address_name
                                    : address?.address_type}
                                </span>
                                <span>
                                  {`${address?.line1}, ${address?.city}, ${address?.postal_code}`}
                                </span>
                              </span>
                            }
                          />
                        );
                      })}
                  </RadioGroup>
                </TabPanel>
              ))}
            </CustomTabs>
          ) : (
            <RadioGroup
              className="radio-group"
              name="controlled-radio-buttons-group"
            >
              {addressList &&
                addressList.length &&
                addressList.map((address) => {
                  return (
                    <FormControlLabel
                      value={address.id}
                      name="radio"
                      checked={currentValue.value == address.id}
                      control={
                        <BpRadio
                          onClick={() =>
                            setCurrentValue({
                              label: `${
                                currentTab == 1
                                  ? "Corporate Address :"
                                  : "Seller Address :"
                              } ${
                                address?.address_name
                                  ? address?.address_name
                                  : address?.address_type
                              } : ${address?.line1}. ${address?.city}. ${
                                address?.postal_code
                              }`,
                              value: address.id,
                            })
                          }
                        />
                      }
                      label={
                        <span
                          className="address"
                          onClick={() =>
                            setCurrentValue({
                              label: `${
                                currentTab == 1
                                  ? "Corporate Address :"
                                  : "Seller Address :"
                              } ${
                                address?.address_name
                                  ? address?.address_name
                                  : address?.address_type
                              } : ${address?.line1}. ${address?.city}. ${
                                address?.postal_code
                              }`,
                              value: address.id,
                            })
                          }
                        >
                          <span className="addressName">
                            {address?.address_name
                              ? address?.address_name
                              : address?.address_type}
                          </span>
                          <span>
                            {`${address?.line1}, ${address?.city}, ${address?.postal_code}`}
                          </span>
                        </span>
                      }
                    />
                  );
                })}
            </RadioGroup>
          )}
        </div>
      )}
    </div>
  );
};

export default AddressSelect;
