import "./FilterDropdown.scss";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import CustomizedRadios from "../radio/Radio";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MuiCheckbox from "@mui/material/Checkbox";
import checkBoxBase from "../../assets/img/checkBoxBase.svg";
import checkBoxPrimary from "../../assets/img/CheckboxPrimary.svg";
import { Button } from "@mui/material";

const isArrayWithTruthyValues = (arr) => {
  return arr?.length > 0 && arr?.every((item) => !!item);
};

const FilterDropdown = (props) => {
  const {
    title,
    subtitle,
    list,
    result,
    isHorizontal,
    radioValue,
    type,
    isVerticalRadio,
    isSearch,
    CustomComponent,
    extraComponent,
    radioUpload,
    closeRadioUpload,
    isCalendar,
    noBorder,
    filtersChoosen,
    showFocused,
    height = "auto",
    isMiniDatePicker,
    dateRange,
    isCategorytab,
  } = props;
  const dispatch = useDispatch();
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cardDetails, setCardDetail] = React.useState(false);
  const [listArray, setListArray] = React.useState([]);
  const [selectTab, setelectedTab] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (closeRadioUpload) {
      closeRadioUpload(false);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCardDetail(!cardDetails);
  };
  React.useEffect(() => {
    if (radioUpload) {
      setAnchorEl(null);
    }
  }, [radioUpload]);

  React.useEffect(() => {
    if (isMiniDatePicker) setAnchorEl(null);
    if (radioValue !== "custom") {
      setAnchorEl(null);
      setCardDetail(!cardDetails);
    }
  }, [radioValue]);

  React.useEffect(() => {
    if (isArrayWithTruthyValues(dateRange)) setAnchorEl(null);
  }, [dateRange]);

  React.useEffect(() => {
    if (filtersChoosen && filtersChoosen.length) {
      setListArray(filtersChoosen);
    } else {
      setListArray([]);
    }
  }, [filtersChoosen]);
  const onChangeCheck = (val, item) => {
    let newArry = [...listArray];
    let found = newArry.findIndex((f) => f == item.value);
    if (found < 0) {
      newArry.push(item.value);
    } else {
      newArry.splice(found, 1);
    }

    setListArray(newArry);
    result(newArry);
  };

  const onChangeRadio = (val, item) => {
    result(val);
    if (val !== "custom") {
      setAnchorEl(null);
      setCardDetail(!cardDetails);
    }
  };
  const updatedTab = (tabId) => {
    setelectedTab(tabId);
    // dispatch(setSelectedTab(tabId));
  };

  return (
    <div
      className={`filter-dropdown-container-component ${
        showFocused && (filtersChoosen?.length > 0 || radioValue)
          ? "selected-dropdown"
          : ""
      } ${open ? "open" : ""} ${globalDarkMode ? "pclive-dark-mode" : ""}
      ${noBorder ? "no-border" : ""}
      `}
      style={{
        borderRadius: isCalendar ? "8px" : "8px 0 0 8px",
        borderRight: isCalendar ? "1" : "0",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <div
          className={`dropdown-element `}
          onClick={(e) => {
            setCardDetail(!cardDetails);
            handleClick(e);
          }}
        >
          {CustomComponent ? (
            <CustomComponent />
          ) : (
            <>
              {" "}
              <span className="text-sm-semibold-14">{title}</span>{" "}
              {!anchorEl ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </>
          )}
        </div>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.8,
            ml: 5,
            padding: 2,
            minWidth: 170,
            backgroundColor: globalDarkMode ? "#292828" : "#ffffff",
            height: height,
            "& .MuiAvatar-root": {
              width: 32,
              maxWidth: 72,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
              backgroundColor: globalDarkMode ? "#1C1D1D" : "#ffffff",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{
          horizontal: isCalendar || isSearch ? "center" : "right",
          vertical: "bottom",
        }}
      >
        <div
          className={`filter-container-dropdown  ${
            extraComponent && isCalendar ? "extra" : ""
          } ${globalDarkMode ? "pclive-dark-mode" : ""}`}
        >
          {isCategorytab && (
            <div className="tabs">
              <span
                className={`text-sm-semibold-14 ${
                  selectTab == 1 ? "selected" : ""
                }`}
                onClick={() => updatedTab(1)}
              >
                {selectTab == 1 ? (
                  <Button label={"Favorite"} />
                ) : (
                  <Button label={"Favorite"} />
                )}
              </span>
              <span
                className={`text-sm-semibold-14 ${
                  selectTab == 2 ? "selected" : ""
                }`}
                onClick={() => updatedTab(2)}
              >
                {selectTab == 2 ? (
                  <Button label={"All"} />
                ) : (
                  <Button label={"All"} />
                )}
              </span>
            </div>
          )}
          {type == "radio" ? (
            <>
              <CustomizedRadios
                contentAlign="flex-start"
                data={list}
                onChange={(e) => onChangeRadio(e.target.value)}
                value={radioValue}
                isVerticalRadio={isVerticalRadio}
              />
              {extraComponent}
            </>
          ) : (
            <ul
              className={isHorizontal ? "horizontal-list" : ""}
              style={
                !isHorizontal ? { maxHeight: "300px", overflow: "auto" } : null
              }
            >
              {list && list.length
                ? list.map((item) => {
                    return (
                      <li className="text-sm-medium-14">
                        <MuiCheckbox
                          icon={<img src={checkBoxBase} />}
                          checkedIcon={<img src={checkBoxPrimary} />}
                          onChange={(e) =>
                            onChangeCheck(e.target.checked, item)
                          }
                          checked={listArray?.includes(item.value)}
                        />
                        {item.label ? item.label : item.category_name}
                      </li>
                    );
                  })
                : ""}
            </ul>
          )}
        </div>
      </Menu>
    </div>
  );
};

export default FilterDropdown;
