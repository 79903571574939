import React from "react";
import "./TermsAndCondition.scss";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, Typography, } from '@mui/material';
import { FAQ_1, FAQ_1_DETAILS } from "constants";

const FAQ = () => {
  return (
    <div className="terms-container">
      <h4>FAQ</h4>
      <div className="contents">
        <Accordion expanded >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{FAQ_1}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography >
              {FAQ_1_DETAILS} 
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
