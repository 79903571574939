import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  CardHeader,
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import { login } from "../actions/HomeAction";
import { useHistory } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "shared/alert/Alert";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email:false,
    password:false
  });
  const [openAlert, setOpenAlert] = useState({
    open: false,
    type: "",
    message: "",
  });
  const history = useHistory();

  const accessToken = localStorage.getItem("accessToken");
  useEffect(() => {}, [accessToken]);



  const isValid = ()=>{ 

   let error = {};
   if(email==='' && password===''){
    error.email =true
    error.password =true
    setErrors(error);
    // return false

   }
   if(email==="" || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email )){
    error.email=true;
      setErrors(error);
      // return false;
   }
   if(password===""||password.length<6)
       { error.password=true;
       setErrors(error);
      //  return false;
   }       
   setErrors(error);
   if(!error.password && !error.email){
   return true;
   }else{
    return false
   }
}

  const onLogin = () => {

    if(isValid()){
    const data = {
      email,
      password,
      is_from_admin:true
    };
    const handleSuccess = (body) => {
      if(!body.success){
        setOpenAlert({
          ...openAlert,
          open: true,
          message: body.message,
          type: "error",
        });
        localStorage.clear();
        history.push("/auth/login");
        return;
      }
      setOpenAlert({
        ...openAlert,
        open: true,
        message: body.message,
        type: "success",
      });
      localStorage.setItem("userEmail",data.email)
      setTimeout(() => {
        history.push("/auth/otp");
      }, 1000);
    };
    const handleFail = (body) => {
      setOpenAlert({
        ...openAlert,
        open: true,
        message: body.message,
        type: "error",
      });
    };

    dispatch(login(data, handleSuccess, handleFail));
  }
  };

  const isLoading = useSelector((state) => state.home.isLoading);
  const dispatch = useDispatch();
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Alert
        message={openAlert.message}
        open={openAlert.open}
        handleClose={() => setOpenAlert({ ...openAlert, open: false })}
        alertType={openAlert.type}
      />
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="text-center">LOGIN</CardHeader>
          {/* <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Sign in with</small>
            </div>
            <div className="btn-wrapper text-center">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/github.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Github</span>
              </Button>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/google.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div>
          </CardHeader> */}
          <CardBody className="px-lg-4 py-lg-4">
            <div className="text-center text-muted mb-4">
              {/* <small>Or sign in with credentials</small> */}
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    onChange={(e) => setEmail(e.target.value)}
                  />

                </InputGroup>
                {errors.email ? <span style={{color:'red', fontSize:12}}>Please enter valid registered email ID</span> : ''}

              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}
                  />

                </InputGroup>
                {errors.password ? <span style={{color:'red', fontSize:12}}>Password should be more than 6 character</span> : ''}

              </FormGroup>

              <div className="text-center">
                <Button
                  onClick={onLogin}
                  className="my-4"
                  color="primary"
                  type="button"
                >
                  Sign in
                </Button>

              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
