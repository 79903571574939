import "./CustomTabs.scss";

import { Box, Button } from "@mui/material";

import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

const CustomTabs = (props) => {
  const { handleChange, value, tabData } = props;
  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="custom-tab-container"
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(e, newval) => handleChange(e, newval)}
            aria-label="lab API tabs example"
            className="tab-class"
          >
            {tabData.map((tab) => {
              return (
                <Tab
                  className=" tab-head "
                  label={
                    value == tab.value ? (
                      <Button>{tab.title}</Button>
                    ) : (
                      <Button>{tab.title}</Button>
                    )
                  }
                  value={tab.value}
                />
              );
            })}
          </TabList>
        </Box>
        {props.children}
      </TabContext>
    </Box>
  );
};

export default CustomTabs;
