import './AddressDropdown.scss';

import { useDispatch, useSelector } from "react-redux";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import Select from "@mui/material/Select";

const AddressDropdown = ({
  data,
  label,
  onChange,
  value,
  error,
  errorMsg,
  disabled,
  isSearch,
  isDark,
  inputProps,
  onClick,
  placeholder,
}) => {
  const globalDarkMode = useSelector((state) => state.profile?.globalDarkMode);
  return (
    <div
      className={`mainConatainer ${
        globalDarkMode || isDark ? "pclive-dark-mode" : ""
      }`}
    >
      {label ? (
        <label
          className="text-xs-medium-12"
          style={{
            marginBottom: 10,
            color: "#667085",
            fontSize: "0.75rem",
            fontStyle: "normal",
            fontWeight: 500,
          }}
        >
          {label}
        </label>
      ) : null}
      <FormControl>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          label=""
          className="selectSmall"
          onChange={onChange}
          value={value}
          error={error}
          disabled={disabled}
          inputProps={inputProps}
          onClick={onClick}
          displayEmpty
          renderValue={value ? undefined : () => placeholder}
          sx={{
            color: value ? "#1D2939" : "#aab7c4 !important",
            fontSize: "0.875rem",
            "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: "#D0D5DD",
            },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#D0D5DD",
            },
          }}
        >
          {data.map((item) => {
            return (
              <MenuItem
                sx={{
                  backgroundColor: globalDarkMode || isDark ? "#292828" : "",
                  color: globalDarkMode || isDark ? "#9D9C9C" : "",
                  "&.MuiMenuItem-root:hover": {
                    backgroundColor: globalDarkMode || isDark ? "#292828" : "",
                    color: globalDarkMode || isDark ? "#ffffff" : "",
                  },
                  "&.Mui-selected": {
                    backgroundColor: globalDarkMode || isDark ? "#1C1D1D" : "",
                  },
                }}
                hidden={data?.hidden}
                className="menu-items"
                value={item.value}
              >
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
        {error ? (
          <p
            style={{
              color: "#D92D20",
              marginTop: 8,
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "20px",
            }}
          >
            {errorMsg}
          </p>
        ) : (
          ""
        )}
      </FormControl>
    </div>
  );
};

export default AddressDropdown;
